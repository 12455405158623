<template>
      <div class="w-full sm:p-8 sm:mt-20 sm:px-12 p-4 flex flex-col justify-center items-center">

      <div class="w-full sm:max-w-screen-sm bg-white/10 p-4 rounded-md border-white/10 border">

        <p class="text-white/40 font-brand font-bold mb-8 text-sm">[ For Project Owners ]</p>

        <div class="sm:flex justify-between items-end w-full sm:space-x-8 ">
          
          <div>
            <h3 class="sm:text-2xl text-xl text-turquoise-500 font-brand font-bold mb-2">Looking to Launch on Oxbull?</h3>
            <p class="text-zinc-300 sm:text-lg font-light">We grow big bulls, let's go big bulls.</p>
          </div>
  
          <a href="https://tally.so/r/3yYkOB" target="_blank" class="mt-8 sm:mt-0 btn sm:btn-md btn-sm bg-white text-black sm:text-lg text-sm font-bold font-brand hover:text-white hover:bg-white/20">Apply Now</a>

        </div>
        
      </div>
    </div>
</template>

<script lang="ts" setup>

</script>

<style>

</style>